import axios from "axios";
axios.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    config.headers["content-type"] = "application/json;charset=utf-8";
    // console.log("发送成功!");
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);
axios.interceptors.response.use(
  (response) => {
    // console.log("返回成功!");
    return response;
  },
  (err) => {
    Promise.reject(err);
  }
);
export default axios;
