import { ref } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
export default {
  name: "contactusIndex",
  setup() {
    const store = useStore();
    let arr = [{
      img: require("../../assets/contactus/热线.png"),
      title: "热线：",
      text: "0335-8352973"
    }, {
      img: require("../../assets/contactus/邮箱.png"),
      title: "邮箱：",
      text: "ydbykj@163.com"
    }, {
      img: require("../../assets/contactus/客服.png"),
      title: "客服：",
      text: "DJW0401_222"
    }, {
      img: require("../../assets/contactus/地址.png"),
      title: "地址：",
      text: "河北省秦皇岛市经济技术开发区龙海道69号科技大厦四层"
    }];
    const center = {
      lng: 119.48264,
      lat: 39.93168
    };
    const zoom = 15;
    const markerPosition = {
      lng: 119.48264,
      lat: 39.93168
    };
    const markerTitle = "滨沅国科（秦皇岛）智能科技股份有限公司";
    const leveContacts = ref("");
    const levePhone = ref("");
    const leveEmail = ref("");
    const leveAddr = ref("");
    const insertOne = async () => {
      let res = await store.dispatch("insertOne", {
        leveContacts: leveContacts.value,
        levePhone: levePhone.value,
        leveEmail: leveEmail.value,
        leveAddr: leveAddr.value,
        leveContent: ""
      });
      // console.log(res.data);
      if (res.data.code == 200) {
        ElMessage({
          message: "提交成功，工作人员会尽快联系您",
          type: "success"
        });
        leveContacts.value = "";
        levePhone.value = "";
        leveEmail.value = "";
        leveAddr.value = "";
      }
    };
    const postData = () => {
      // console.log("联系人：", leveContacts.value);
      // console.log("联系方式：", levePhone.value);
      // console.log("邮箱：", leveEmail.value);
      // console.log("地址：", leveAddr.value);
      if (levePhone.value) {
        insertOne();
      } else {
        ElMessage({
          message: "请填写联系电话！",
          type: "warning"
        });
      }
    };
    return {
      arr,
      center,
      zoom,
      markerPosition,
      markerTitle,
      leveContacts,
      levePhone,
      leveEmail,
      leveAddr,
      insertOne,
      postData
    };
  }
};