import { ref } from "vue";
export default {
  name: "HomeIndex",
  props: {
    msg: String
  },
  setup() {
    const active = ref("home");
    return {
      active
    };
  },
  onMounted() {
    console.log(111);
  }
};