import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-92a182a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "w100"
};
const _hoisted_2 = {
  class: "w80"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.arr, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      onClick: $event => $setup.getXiangxiData(item)
    }, [_createElementVNode("img", {
      src: $setup.imgUrlHttp + item.productImg,
      alt: ""
    }, null, 8, _hoisted_4), _createElementVNode("p", null, _toDisplayString(item.productTitle), 1)], 8, _hoisted_3);
  }), 128))])]);
}