import { reactive, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "recruitmentIndex",
  setup() {
    const store = useStore();
    const activeNames = ref(["1"]);
    let dataList = reactive([]);
    let imgList = [{
      img: require("../../assets/recruitment/五险一金.png"),
      text: "五险一金"
    }, {
      img: require("../../assets/recruitment/周末双休.png"),
      text: "周末双休"
    }, {
      img: require("../../assets/recruitment/年终奖.png"),
      text: "年终奖"
    }, {
      img: require("../../assets/recruitment/定期体检.png"),
      text: "定期体检"
    }, {
      img: require("../../assets/recruitment/带薪年假.png"),
      text: "带薪年假"
    }, {
      img: require("../../assets/recruitment/节日福利.png"),
      text: "节日福利"
    }, {
      img: require("../../assets/recruitment/加班补助.png"),
      text: "加班补助"
    }, {
      img: require("../../assets/recruitment/多次调薪.png"),
      text: "多次调薪"
    }];
    const currentPage = ref(1);
    const dataNum = ref(1);
    const handleChange = val => {
      console.log(val);
    };
    const setcurrentPage = val => {
      console.log(val);
    };
    const workJoblist = async () => {
      let res = await store.dispatch("workJoblist", {
        pageNum: currentPage.value,
        pageSize: 6,
        jobName: ""
      });
      dataList.splice(0, dataList.length, ...res.data.rows);
      dataList.forEach(i => {
        if (i.jobEducation == 1) {
          i.jobEducationT = "硕士及以上";
        }
        if (i.jobEducation == 2) {
          i.jobEducationT = "本科及以上";
        }
        if (i.jobEducation == 3) {
          i.jobEducationT = "专科及以上";
        }
        if (i.jobEducation == 4) {
          i.jobEducationT = "高中及以上";
        }
        if (i.jobEducation == 5) {
          i.jobEducationT = "博士";
        }
      });
      console.log(res);
      dataNum.value = res.data.total;
    };
    return {
      imgList,
      activeNames,
      handleChange,
      setcurrentPage,
      dataList,
      workJoblist,
      currentPage
    };
  },
  mounted() {
    this.workJoblist();
  }
};