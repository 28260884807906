import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0fac0e59"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "width": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_tabbar, {
    route: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/home",
      name: "home",
      icon: "home-o"
    }, {
      default: _withCtx(() => [_createTextVNode("首页")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/aboutIndex",
      name: "aboutIndex",
      icon: "search"
    }, {
      default: _withCtx(() => [_createTextVNode("关于滨沅")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/productCenterIndex",
      name: "productCenterIndex",
      icon: "friends-o"
    }, {
      default: _withCtx(() => [_createTextVNode("产品")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/newsIndex",
      name: "newsIndex",
      icon: "setting-o"
    }, {
      default: _withCtx(() => [_createTextVNode("资讯")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/contactusIndex",
      name: "contactusIndex",
      icon: "setting-o"
    }, {
      default: _withCtx(() => [_createTextVNode("我们")]),
      _: 1
    })]),
    _: 1
  })]);
}