import { createApp } from "vue";
import App from "./App.vue";
import router from "./utils/router";
import store from "./utils/store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import BaiduMap from "vue-baidu-map-3x";

const app = createApp(App);
// app.component("BaiduMap");
app.use(BaiduMap, {
  ak: "09LVcmzEhc5oGGqS1lnRctfN5glFaZiE",
});
// 挂载Vue Router
app.use(router);
app.use(ElementPlus);
// 挂载Vuex
app.use(store);
app.mount("#app");
