import "core-js/modules/es.array.push.js";
import { reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "ShebeiComponent",
  setup() {
    const store = useStore();
    const router = useRouter();
    let arr = reactive([]);
    let imgUrlHttp = store.state.imgUrlHttp;
    const getData = async () => {
      let res = await store.dispatch("typeproduct", {
        productType: 1,
        rows: ""
      });
      arr.splice(0, arr.length, ...res.data.data);
      // console.log(arr);
    };

    const getXiangxiData = res => {
      store.state.xiangxiData = res;
      store.state.xiangxiType = "设备管控";
      router.push("/XiangxiDataComponent");
    };
    return {
      arr,
      imgUrlHttp,
      getData,
      getXiangxiData
    };
  },
  mounted() {
    this.getData();
  }
};