import { ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
export default {
  name: "indetailComponent",
  setup() {
    const store = useStore();
    // const router = useRouter();
    let imgUrlHttp = store.state.imgUrlHttp;
    let data = ref({});
    // const onClickLeft = () => history.back();
    const getData = async () => {
      let res = await store.dispatch("getData", {
        infoId: sessionStorage.getItem("infoId")
      });
      data.value = res.data.data;
      console.log(data.value, "新闻详情");
    };
    return {
      imgUrlHttp,
      // onClickLeft,
      getData,
      data
    };
  },
  mounted() {
    this.getData();
  }
};