import { useStore } from "vuex";
import { reactive } from "vue";
export default {
  name: "XiangxiDataComponent",
  setup() {
    const store = useStore();
    const onClickLeft = () => history.back();
    const title = reactive(store.state.xiangxiData.productTitle);
    const type = reactive(store.state.xiangxiType);
    const data = reactive({});
    let imgUrlHttp = store.state.imgUrlHttp;
    const getData = async () => {
      const res = await store.dispatch("get", {
        productId: store.state.xiangxiData.productId
      });
      data.value = res.data.data;
      // console.log(data.value);
    };

    return {
      onClickLeft,
      imgUrlHttp,
      title,
      type,
      getData,
      data
    };
  },
  mounted() {
    this.getData();
  }
};