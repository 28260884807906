import "core-js/modules/es.array.push.js";
import { reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "newsIndex",
  setup() {
    const store = useStore();
    const router = useRouter();
    let imgUrlHttp = store.state.imgUrlHttp;
    let hyList = reactive([]);
    let gsList = reactive([]);
    const hninfo = async () => {
      let res = await store.dispatch("hninfo");
      hyList.splice(0, hyList.length, ...res.data.data);
      console.log(hyList, "hyList");
    };
    const ydinfo = async () => {
      let res = await store.dispatch("ydinfo");
      gsList.splice(0, gsList.length, ...res.data.data);
      console.log(gsList, "hyList");
    };
    const goDataList = res => {
      store.state.xwTitle = res;
      sessionStorage.setItem("xwTitle", res);
      router.push("/dataList");
    };
    const goGetData = (res, text) => {
      sessionStorage.setItem("infoId", res.infoId);
      console.log(text);
      router.push("/indetail");
    };
    return {
      imgUrlHttp,
      hninfo,
      hyList,
      ydinfo,
      gsList,
      goDataList,
      goGetData
    };
  },
  mounted() {
    this.hninfo();
    this.ydinfo();
  }
};