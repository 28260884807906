import "core-js/modules/es.array.push.js";
import { ref, watch, computed, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
// import store from "./utils/store";
export default {
  name: "App",
  // components: {
  //   HomeIndex,
  // },
  setup() {
    const router = useRouter();
    const list = [...Array(50).keys()];
    const menuList = ref([{
      img: require("./assets/home/首页.png"),
      title: "首页",
      active: false,
      push: "/home"
    }, {
      img: require("./assets/home/关于滨沅.png"),
      title: "关于滨沅",
      active: false,
      push: "/aboutIndex"
    }, {
      img: require("./assets/home/产品解决方案.png"),
      title: "产品解决方案",
      active: false,
      push: "/productCenterIndex"
    }, {
      img: require("./assets/home/新闻资讯.png"),
      title: "新闻资讯",
      active: false,
      push: "/newsIndex"
    }, {
      img: require("./assets/home/人才招聘.png"),
      title: "人才招聘",
      active: false,
      push: "/recruitmentIndex"
    }, {
      img: require("./assets/home/联系我们.png"),
      title: "联系我们",
      active: false,
      push: "/contactusIndex"
    }]);
    var goBackBool = ref(false);
    var menuBool = ref(false);
    const currentRoute = ref(window.location.hash.replace(/^#/, ""));
    const setSidebar = () => {
      menuBool.value = !menuBool.value;
    };
    const goPush = res => {
      router.push(res.push);
    };
    const goBack = () => {
      router.go(-1);
    };
    // 监听路由变化
    watch(() => router.currentRoute.value.path, path => {
      if (path === "/home") {
        goBackBool.value = false;
        menuList.value.forEach(i => {
          if (i.push == path) {
            i.active = true;
          } else {
            i.active = false;
          }
        });
      } else if (path === "/aboutIndex") {
        goBackBool.value = true;
        menuList.value.forEach(i => {
          if (i.push == path) {
            i.active = true;
          } else {
            i.active = false;
          }
        });
      } else if (path === "/productCenterIndex") {
        goBackBool.value = true;
        menuList.value.forEach(i => {
          if (i.push == path) {
            i.active = true;
          } else {
            i.active = false;
          }
        });
      } else if (path === "/newsIndex") {
        goBackBool.value = true;
        menuList.value.forEach(i => {
          if (i.push == path) {
            i.active = true;
          } else {
            i.active = false;
          }
        });
      } else if (path === "/recruitmentIndex") {
        goBackBool.value = true;
        menuList.value.forEach(i => {
          if (i.push == path) {
            i.active = true;
          } else {
            i.active = false;
          }
        });
      } else if (path === "/contactusIndex") {
        goBackBool.value = true;
        menuList.value.forEach(i => {
          if (i.push == path) {
            i.active = true;
          } else {
            i.active = false;
          }
        });
      } else if (path === "/indetail") {
        goBackBool.value = true;
      }
      menuBool.value = false;
    });
    const menuStyle = computed(() => item => ({
      backgroundColor: item.active ? "#006aff" : "rgba(255, 255, 255, 0.05)",
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "calc(100vw * 250 / 1920)",
      justifyContent: "space-between",
      paddingLeft: "5%",
      paddingRight: "5%",
      boxSizing: "border-box"
    }));
    const deviceInfo = reactive({
      isMobile: false,
      isTablet: false,
      isDesktop: false
    });
    onMounted(() => {
      const userAgent = window.navigator.userAgent;
      deviceInfo.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      deviceInfo.isTablet = /iPad|Android/i.test(userAgent) && !/Mobile/i.test(userAgent);
      deviceInfo.isDesktop = !deviceInfo.isMobile && !deviceInfo.isTablet;

      // if (deviceInfo.isMobile) {
      //   console.log("移动设备");
      // }
      // if (deviceInfo.isTablet) {
      //   console.log("平板设备");
      // }
      if (deviceInfo.isDesktop) {
        // console.log("桌面设备");
        window.location.href = "https://www.ydbykj.com/";
      }
    });
    return {
      router,
      list,
      menuBool,
      setSidebar,
      currentRoute,
      goBack,
      goBackBool,
      menuList,
      menuStyle,
      goPush,
      deviceInfo
    };
  },
  mounted() {
    if (this.currentRoute == "/home") {
      this.active = 0;
    }
    if (this.currentRoute == "/aboutIndex") {
      this.active = 1;
    }
    if (this.currentRoute == "/productCenterIndex") {
      this.active = 2;
    }
    if (this.currentRoute == "/newsIndex") {
      this.active = 3;
    }
    if (this.currentRoute == "/contactusIndex") {
      this.active = 4;
    }
    // console.log(this.active);
    // console.log(this.currentRoute);
  }
};