import { createRouter,createWebHistory } from "vue-router";
import HomeIndex from "../components/HomeIndex.vue";
import HomeComponent from "../components/Home.vue";
import aboutIndex from "../components/aboutBY/index.vue";
import productCenterIndex from "../components/productCenter/index.vue";
import XiangxiDataComponent from "../components/productCenter/XiangxiDataComponent.vue";
import newsIndex from "../components/news/index.vue";
import dataList from "../components/news/dataList.vue";
import indetailComponent from "../components/news/indetail.vue";
import recruitmentIndex from "../components/recruitment/index.vue";
import contactusIndex from "../components/contactus/index.vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/index",
    name: "首页",
    component: HomeIndex,
  },
  {
    path: "/home",
    name: "首页",
    component: HomeComponent,
  },
  {
    path: "/aboutIndex",
    name: "关于滨沅",
    component: aboutIndex,
  },
  {
    path: "/productCenterIndex",
    name: "产品中心",
    component: productCenterIndex,
  },
  {
    path: "/XiangxiDataComponent",
    name: "产品详细信息",
    component: XiangxiDataComponent,
  },
  {
    path: "/newsIndex",
    name: "新闻资讯",
    component: newsIndex,
  },
  {
    path: "/dataList",
    name: "新闻列表",
    component: dataList,
  },
  {
    path: "/indetail",
    name: "新闻详细",
    component: indetailComponent,
  },
  {
    path: "/recruitmentIndex",
    name: "人才招聘",
    component: recruitmentIndex,
  },
  {
    path: "/contactusIndex",
    name: "联系我们",
    component: contactusIndex,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name || "滨沅国科（秦皇岛）智能科技股份有限公司"; // 根据路由的 name 属性设置浏览器标题
  // console.log("从" + from.path + "到" + to.path);
  window.scrollTo(0, 0); // 将浏览器滚动条置顶
  next();
});

export default router;
