import { createStore } from "vuex";
import ajax from "./ajax";

export default createStore({
  state() {
    return {
      imgUrlHttp: "gw/prod-api",
      xiangxiData: "",
      xiangxiType: "",
      xwTitle: "",
    };
  },
  getters: {},
  actions: {
    async typeproduct(productType, rows) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/product/open/typeproduct",
        data: {
          productType: rows.productType,
          rows: rows.rows,
        },
      });
      return result;
    },
    async get(context, productId) {
      console.log(productId.productId);
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/product/open/get",
        data: {
          productId: productId.productId,
        },
      });
      return result;
    },
    async hninfo() {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/information/open/hninfo",
      });
      return result;
    },
    async hninfo2() {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/information/open/yd/hninfo2",
      });
      return result;
    },
    async ydinfo() {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/information/open/ydinfo",
      });
      return result;
    },
    async list({ commit }, { infoType, pageNum, pageSize }) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/information/open/list",
        data: {
          infoType: infoType,
          pageNum: pageNum,
          pageSize: pageSize,
        },
      });
      commit("SET_LIST", result);
      return result;
    },
    async getData({ commit }, { infoId }) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/information/open/get",
        data: {
          infoId: infoId,
        },
      });
      commit("SET_LIST", result.data.data);
      return result;
    },
    async developlist() {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/develop/open/developlist",
      });
      return result;
    },
    async workJoblist({ commit }, { pageNum, pageSize, jobName }) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/jobs/open/workJoblist",
        data: {
          pageNum: pageNum,
          pageSize: pageSize,
          jobName: jobName,
        },
      });
      commit("SET_LIST", result);
      return result;
    },
    async smsSendMsg({ phoneNumber }) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/sms/smsSendMsg",
        data: {
          phoneNumber: phoneNumber,
        },
      });
      return result;
    },
    async insertOne(
      { commit },
      { leveContacts, levePhone, leveEmail, leveAddr, leveContent }
    ) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/msg/open/insertOne",
        data: {
          leveContacts: leveContacts,
          levePhone: levePhone,
          leveEmail: leveEmail,
          leveAddr: leveAddr,
          leveContent: leveContent,
        },
      });
      commit("SET_LIST", result);
      return result;
    },
    async sendCode({ phone }) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/sms/sendCode",
        data: {
          phone: phone,
        },
      });
      return result;
    },
    async insertRow({
      contactName,
      phone,
      email,
      company,
      province,
      industry,
      position,
      demand,
      code,
    }) {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/contact/open/insertRow",
        data: {
          contactName: contactName,
          phone: phone,
          email: email,
          company: company,
          province: province,
          industry: industry,
          position: position,
          demand: demand,
          companyIdentity: 1,
          code: code,
        },
      });
      return result;
    },
    async coreproduct() {
      let result = await ajax({
        method: "POST",
        baseURL: "/gw/prod-api",
        url: "/ydby/product/open/coreproduct",
      });
      return result;
    },
  },
});
