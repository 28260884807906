import { ref } from "vue";
import ShebeiComponent from "./ShebeiComponent.vue";
import AnquanComponent from "./AnquanComponent.vue";
import ShengchanComponentVue from "./ShengchanComponent.vue";
import HuanbaoComponent from "./HuanbaoComponent.vue";
export default {
  name: "productCenterIndex",
  components: {
    ShebeiComponent,
    AnquanComponent,
    ShengchanComponentVue,
    HuanbaoComponent
  },
  setup() {
    const activeName = ref("first");
    const handleClick = (tab, event) => {
      console.log(tab, event);
    };
    return {
      activeName,
      handleClick
    };
  }
};