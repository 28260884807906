import "core-js/modules/es.array.push.js";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "dataList",
  setup() {
    const store = useStore();
    const currentPage = ref(1);
    const router = useRouter();
    const pageNums = ref(0);
    let title = store.state.xwTitle ? store.state.xwTitle : sessionStorage.getItem("xwTitle");
    let imgUrlHttp = store.state.imgUrlHttp;
    // const onClickLeft = () => history.back();
    let dataList = reactive([]);
    const list = async () => {
      let res = await store.dispatch("list", {
        infoType: title == "行业新闻" ? 1 : 2,
        pageNum: currentPage.value,
        pageSize: 10
      });
      dataList.splice(0, dataList.length, ...res.data.rows);
      pageNums.value = res.data.total;
      console.log(dataList, "dataList");
    };
    const pageFun = () => {
      list();
      window.scrollTo(0, 0); // 将浏览器滚动条置顶
    };

    const goGetData = res => {
      sessionStorage.setItem("infoId", res.infoId);
      router.push("/indetail");
    };
    return {
      currentPage,
      // onClickLeft,
      title,
      imgUrlHttp,
      list,
      dataList,
      pageNums,
      pageFun,
      goGetData
    };
  },
  mounted() {
    this.list();
  }
};