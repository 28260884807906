import { ref } from "vue";
import qyComponent from "./qyComponent.vue";
import zzComponent from "./qyComponent.vue";
import zsComponent from "./qyComponent.vue";
export default {
  name: "aboutIndex",
  components: {
    qyComponent,
    zzComponent,
    zsComponent
  },
  setup() {
    // let indexPage = ref(1);
    // const activeDiv = (str) => {
    //   if (str == "企业介绍") {
    //     let q = document.querySelector(".qiyejieshao");
    //     q.style.color = "#006aff";
    //     q.style.background = "rgba(217, 217, 217, 0.1)";
    //     let two = document.querySelector(".zizhirenzheng");
    //     two.style.color = "white";
    //     two.style.background = "transparent";
    //     let three = document.querySelector(".zhishichanquan");
    //     three.style.color = "white";
    //     three.style.background = "transparent";
    //     indexPage.value = 1;
    //   }
    //   if (str == "资质认证") {
    //     let z = document.querySelector(".zizhirenzheng");
    //     z.style.color = "#006aff";
    //     z.style.background = "rgba(217, 217, 217, 0.1)";
    //     let one = document.querySelector(".qiyejieshao");
    //     one.style.color = "white";
    //     one.style.background = "transparent";
    //     let three = document.querySelector(".zhishichanquan");
    //     three.style.color = "white";
    //     three.style.background = "transparent";
    //     indexPage.value = 2;
    //   }
    //   if (str == "知识产权") {
    //     let three = document.querySelector(".zhishichanquan");
    //     three.style.color = "#006aff";
    //     three.style.background = "rgba(217, 217, 217, 0.1)";
    //     let one = document.querySelector(".qiyejieshao");
    //     one.style.color = "white";
    //     one.style.background = "transparent";
    //     let two = document.querySelector(".zizhirenzheng");
    //     two.style.color = "white";
    //     two.style.background = "transparent";
    //     indexPage.value = 3;
    //   }
    // };
    const active = ref(0);
    return {
      // activeDiv,
      // indexPage,
      active
    };
  },
  mounted() {
    // let q = document.querySelector(".qiyejieshao");
    // q.style.color = "#006aff";
    // q.style.background = "rgba(217, 217, 217, 0.1)";
    // let two = document.querySelector(".zizhirenzheng");
    // two.style.color = "white";
    // two.style.background = "transparent";
    // let three = document.querySelector(".zhishichanquan");
    // three.style.color = "white";
    // three.style.background = "transparent";
  }
};