import "core-js/modules/es.array.push.js";
import { reactive, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "HomeComponent",
  setup() {
    const store = useStore();
    let dataList = reactive([]);
    const router = useRouter();
    let imgUrlHttp = store.state.imgUrlHttp;
    const ysList = [{
      img: require("../assets/home/资质齐全.png"),
      img2: require("../assets/home/资质齐全.png"),
      title: "资质齐全",
      text: "三大体系证书、建筑业资质等资质认证保障了公司工程项目标准化流程化的管理标准、实施方案和服务品质。"
    }, {
      img: require("../assets/home/行业领先.png"),
      title: "行业领先",
      text: "公司坚持自主研发创新，使产品具备了行业领先的竞争力。智慧生产管控系统等产品已广泛应用到中国各大港口。"
    }, {
      img: require("../assets/home/先进技术.png"),
      title: "先进技术",
      text: "二十余项专利和软著让公司技术始终保持前沿地位，机器视觉等关键技术屡次斩获河北省科学技术奖。"
    }, {
      img: require("../assets/home/专业人才.png"),
      title: "专业人才",
      text: "公司拥有数十位行业专家和科研人才智库团队，公司团队硕士研究生等高端人才占比80%以上。"
    }, {
      img: require("../assets/home/产学研结合.png"),
      title: "产学研结合",
      text: "公司与燕山大学共建“产学研合作基地”，为企业研发创新项目的产业化奠定坚实基础。"
    }];
    const list = async () => {
      let res = await store.dispatch("hninfo2");
      dataList.splice(0, dataList.length, ...res.data.data);
    };
    const goGetData = res => {
      sessionStorage.setItem("infoId", res.infoId);
      router.push("/indetail");
    };
    const imgs = ref([{
      img: require("../assets/home/swiper/设备管控-白色.png"),
      img2: require("../assets/home/swiper/设备管控-蓝色.png"),
      text: "设备管控"
    }, {
      img: require("../assets/home/swiper/生产管控-白色.png"),
      img2: require("../assets/home/swiper/生产管控-蓝色.png"),
      text: "生产管控"
    }, {
      img: require("../assets/home/swiper/安全管控-白色.png"),
      img2: require("../assets/home/swiper/安全管控-蓝色.png"),
      text: "安全管控"
    }, {
      img: require("../assets/home/swiper/环保管控-白色.png"),
      img2: require("../assets/home/swiper/环保管控-蓝色.png"),
      text: "环保管控"
    }]);
    const translateX = ref(0);
    const currentIndex = ref(0);
    watch(currentIndex, (newValue, oldValue) => {
      if (newValue === 0) {
        translateX.value = 0;
        oldValue ? oldValue : oldValue;
      } else if (newValue % 3 === 0) {
        translateX.value -= 100;
        oldValue ? oldValue : oldValue;
      }
    });
    const itemIndex = ref(0);
    const currentImg = computed(() => {
      return index => {
        return currentIndex.value === index ? imgs.value[index].img2 : imgs.value[index].img;
      };
    });
    function handleChange(index) {
      if (currentIndex.value === index) {
        return;
      }
      if (currentIndex.value !== null) {
        const prevIndex = currentIndex.value;
        currentIndex.value = null;
        setTimeout(() => {
          currentIndex.value = prevIndex;
        }, 0);
      }
      setTimeout(() => {
        currentIndex.value = index;
      }, 0);
      if (index === Math.floor(imgs.value.length / 2)) {
        currentImg.value = index => {
          return imgs.value[index].img2;
        };
      } else {
        currentImg.value = index => {
          return imgs.value[index].img;
        };
      }
    }
    const goProductCenterIndex = () => {
      router.push("/productCenterIndex");
    };
    return {
      ysList,
      router,
      imgUrlHttp,
      list,
      dataList,
      goGetData,
      imgs,
      handleChange,
      itemIndex,
      currentImg,
      goProductCenterIndex
    };
  },
  mounted() {
    this.list();
  }
};